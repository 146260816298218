


























































// TODO - STANDARDS Tooltip ranges
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, reactive, ref, watch } from '@vue/composition-api';
// Types -------------------------------------------------------------------------------
export type TData = {
  name: string;
  value: number;
  ranges: TRanges;
  unit: string;
  roundedValue: boolean;
};
export type TRanges = {
  base: TRangesMinMax;
  minRange: TRangesMinMax;
  maxRange: TRangesMinMax;
  extremMin: number;
  extremMax: number;
};
export type TRangesMinMax = {
  min: number;
  max: number;
};
//
export type TColors = {
  [key: string]: any;
  neutralValue: string;
  neutral: string;
  goodRange: string;
  warningRange: string;
  badRange: string;
};
export type TSections = {
  [key: string]: any;
  low: string;
  mediumLow: string;
  neutral: string;
  mediumHigh: string;
  high: string;
};
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartStandardGaugeSections',
  props: {
    unit: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    norms: {
      type: Object
    },
    simple: {
      type: Boolean
    },
    valid: {
      type: Boolean,
      default: true
    },
    noRange: {
      type: Boolean
    },
    imperial: {
      type: Boolean
    }
  },
  setup(properties) {
    const value = properties.data.value
      ? properties.data.value
      : properties.data.global[properties.imperial ? 'imp_avg' : 'avg'] || 0;
    const status = properties.data.status ? properties.data.status : properties.data.global?.range || null;

    const valueColor = ref('');
    const colors: TColors = reactive({
      neutralValue: 'var(--v-walking-base)',
      neutral: '#F2F2F2',
      goodRange: '#33CC30',
      warningRange: '#FFAA02',
      badRange: '#ED361E'
    });
    const sections: TSections = reactive({
      low: colors.neutral,
      mediumLow: colors.neutral,
      neutral: colors.neutral,
      mediumHigh: colors.neutral,
      high: colors.neutral
    });

    function setColor(type: string, colorType: string) {
      for (const [key, value] of Object.entries(sections)) {
        sections[key] = colors[colorType];
        valueColor.value = properties.noRange || !properties.norms ? colors.neutralValue : colors[colorType];
        if (key === type) return;
      }
    }

    function clearColors() {
      for (const key of Object.keys(sections)) {
        sections[key] = colors.neutral;
      }
    }

    function getColors() {
      clearColors();
      switch (status) {
        case 'very_low':
          setColor('low', 'badRange');
          break;
        case 'low':
          setColor('mediumLow', 'warningRange');
          break;
        case 'high':
          setColor('mediumHigh', 'warningRange');
          break;
        case 'very_high':
          setColor('high', 'badRange');
          break;
        case 'neutral':
          setColor('neutral', 'goodRange');
          break;
        default:
          setColor('neutral', 'neutral');
      }
    }

    watch(
      () => properties.data,
      () => {
        if (properties.valid) {
          getColors();
        } else {
          setColor('neutral', 'neutral');
        }
      },
      { immediate: true, deep: true }
    );

    return {
      // Values
      value,
      valueColor,
      sections
    };
  }
});
