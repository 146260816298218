









































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, computed, watch, PropType } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useAnalytics } from '@/utils/analytics.utils';
// -------------------------------------------------------------------------------------------------

export type TValueInformations = {
  type: string;
  normsType?: number[];
  unit?: string;
};

export default defineComponent({
  name: 'ElementResultsTooltip',
  props: {
    values: {
      type: Array as PropType<TValueInformations[]>,
      required: true
    },
    analyticsData: {
      type: Object,
      required: false,
      default: null
    },
    iconSize: {
      type: Number,
      default: 14
    }
  },
  setup(properties) {
    const { trackSuccess } = useAnalytics();

    const onHover = ref(false);
    const haveMultipleValues = computed(() => properties.values.length > 1);

    function breakLine(value: string) {
      return value.replace(/(\r\n|\n)/g, '<br/><br/>');
    }

    watch(onHover, (value: boolean) => {
      if (value && !!properties.analyticsData)
        trackSuccess(properties.analyticsData.type, { analysisType: properties.analyticsData.analysisType });
    });

    return {
      onHover,
      haveMultipleValues,
      breakLine
    };
  }
});
